// Generated by Framer (c75d380)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./OYFegqbig-0.js";

const cycleOrder = ["F3p4x8DKn"];

const serializationHash = "framer-Rsac7"

const variantClassNames = {F3p4x8DKn: "framer-v-19z475w"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {eWWczOETO: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "F3p4x8DKn", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-19z475w", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"F3p4x8DKn"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-mljxru"} data-framer-name={"shape3"} fill={"black"} intrinsicHeight={24} intrinsicWidth={24} layoutDependency={layoutDependency} layoutId={"m8hCUYNiL"} svg={"<svg width=\"24\" height=\"24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M13.608 4.732a.984.984 0 0 1 .97-.036l5.326 8.976a.889.889 0 0 1 .083.688.963.963 0 0 1-.448.572l-8.58 4.953a.963.963 0 0 1-.72.103.889.889 0 0 1-.554-.416l-4.99-8.643a.874.874 0 0 1-.12-.459.984.984 0 0 1 .516-.821.964.964 0 0 1 .49-.11l3.872.14a2.175 2.175 0 0 0 2-1.155L13.267 5.1a.964.964 0 0 1 .34-.37Z\" stroke=\"#F6F6F6\" stroke-width=\"1.2\" fill=\"none\"/></svg>"} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-Rsac7[data-border=\"true\"]::after, .framer-Rsac7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Rsac7.framer-1a7yc45, .framer-Rsac7 .framer-1a7yc45 { display: block; }", ".framer-Rsac7.framer-19z475w { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-Rsac7 .framer-mljxru { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 24px); left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerOYFegqbig: React.ComponentType<Props> = withCSS(Component, css, "framer-Rsac7") as typeof Component;
export default FramerOYFegqbig;

FramerOYFegqbig.displayName = "Nav/Icon -- Shape 3";

FramerOYFegqbig.defaultProps = {height: 24, width: 24};

addFonts(FramerOYFegqbig, [])